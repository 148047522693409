
const HeroComponent = ()=> {
  return (
  <section className="hero">
    <div className="container">
      <div className="row">
        <div className="col-10 offset-1">
          <h1>PRE-ORDERS ARE NOW OPEN</h1>
          <h5>Stop guessing. Start measuring. Secure your spot to purchase the first real-time hydration monitoring technology.<br></br><br></br>Shipping Fall 2022.</h5>
          <div className="btn-group">
            <a className="scroll" href="#form">
              <button className="btn btn-primary">Pre-order Now</button>
            </a>
            <a className="scroll" href="#intro">
              <button className="btn btn-secondary">Learn More</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
 );
}

export default HeroComponent;

const ImageTextComponentLeft = ()=> {
  return (
    <section className="image-text image-left" id="form">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-12 image-col">
            {<img src="./pack_image.png" alt="Nix Product" className="img-fluid" />}
          </div>
          <div className="col-lg-6 col-12 text-col form-column">
            <h2>Secure your pre-order</h2>
            <div className="klaviyo-form-Xtce7u"></div>
          </div>
        </div>
      </div>
    </section>
 );
}

export default ImageTextComponentLeft;

const FooterComponent = ()=> {
  return (
  <section className="footer">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h6>Have Questions? Contact Us: <a href="mailto:info@nixbiosensors.com">info@nixbiosensors.com</a></h6>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
        &copy; 2022 Nix Biosensors - All Rights Reserved
        </div>
      </div>
    </div>
  </section>
 );
}

export default FooterComponent;
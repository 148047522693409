
const NavComponent = ()=> {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        {<img src="/nix-logo.png" alt="" className="navbar-logo"/>}
      </div>
    </nav>
 );
}

export default NavComponent;
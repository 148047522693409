
const IntroTextCoponent = ()=> {
  return (
  <section className="intro grey-bg" id="intro">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2>Nix Hydration Biosensor</h2>
          <p>Endurance athletes know that dehydration has profound consequences on safety and performance, yet there has never been a way to accurately and conveniently determine fluid and electrolyte needs. Until now. <strong>Nix Biosensors</strong> is the world’s first real-time hydration system that tells you when, what, and how much to drink based on the exact volume and composition of your sweat. Useful for any workout over 45 minutes where you are actively sweating, our revolutionary biosensor pairs directly with the Nix app and integrates with your Apple Watch and Garmin devices to notify you of fluid and electrolyte losses throughout your workout and creates a personalized sweat profile to help you optimize performance. <a href="https://nixbiosensors.com/?utm_source=Preorderpage&utm_medium=Referral&utm_campaign=preorder">Explore more about our product and team at nixbiosensors.com.</a>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 intro-img-col">
          <img src="/app_product_image.png" alt="Nix App" className="img-fluid" />
        </div>
      </div>
    </div>
  </section>
 );
}

export default IntroTextCoponent;
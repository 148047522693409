
const SocialComponent = ()=> {
  return (
  <section className="social">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2>Follow Us On Social</h2>
          <div className="social-icons">
            <a href="http://www.facebook.com/nixbiosensors" target="_blank" rel="noreferrer"><i className="fab fa-facebook-square"></i></a>
            <a href="https://www.instagram.com/nixhydration/" target="_blank" rel="noreferrer"><i className="fab fa-instagram-square"></i></a>
            <a href="https://twitter.com/NixBiosensors" target="_blank" rel="noreferrer"><i className="fab fa-twitter-square"></i></a>
          </div>
        </div>
      </div>
    </div>
  </section>
 );
}

export default SocialComponent;

const IconColumnComponent = ()=> {
  return (
  <section className="icon-component grey-bg">
    <div className="container">
      <div className="row">
        <div className="col-md-3 col-sm-12 icon-col">
          {<img src="/Nix_Scientific-106x94.png" alt="Nix Icon Scientific" className="icon img-fluid"/>}
          <h5>SCIENTIFIC</h5>
          <p>Evaluates the biomarkers in your sweat, like a portable lab without the needles.</p>
        </div>
        <div className="col-md-3 col-sm-12 icon-col">
          {<img src="/Nix_Personalized-106x94.png" alt="Nix Icon Personalized" className="icon img-fluid"/>}
          <h5>PERSONALIZED</h5>
          <p>Analyzes your sweat moment-to-moment, not a general calculation.</p>
        </div>
        <div className="col-md-3 col-sm-12 icon-col">
          {<img src="/Nix_Actionable-106x141.png" alt="Nix Icon Actionable" className="icon img-fluid"/>}
          <h5>ACTIONABLE</h5>
          <p>Quantifies when, what, and how much to drink in real-time during your workout.</p>
        </div>
        <div className="col-md-3 col-sm-12 icon-col">
          {<img src="/Nix_Convenient-106x167.png" alt="Nix Icon Convenient" className="icon img-fluid"/>}
          <h5>CONVENIENT</h5>
          <p>Integrates with your phone, sport watch or bike computer. Lightweight and easy to use.</p>
        </div>
      </div>
    </div>
  </section>
 );
}

export default IconColumnComponent;
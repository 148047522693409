import React from 'react';
import './App.css';
import HomeView from './views/HomeView';
import NavComponent from './components/NavComponent';
import FooterComponent from './components/FooterComponent';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";


function App() {
  return (
    <>
      <NavComponent></NavComponent>
      <Router>
        <Routes>
          <Route path='/' element={ <HomeView /> } />
        </Routes>
      </Router>
      <FooterComponent></FooterComponent>
    </>
  );
}

export default App;


import HeroComponent from '../components/HeroComponent';
import IntroTextCoponent from '../components/IntroTextComponent';
import IconColumnComponent from '../components/IconColumnComponent';
import ImageTextComponentLeft from '../components/ImageTextComponentLeft';
import SocialComponent from '../components/SocialComponent';

const HomeView = ()=> {
  return (
    <>
      <HeroComponent></HeroComponent>
      <IntroTextCoponent></IntroTextCoponent>
      <IconColumnComponent></IconColumnComponent>
      <ImageTextComponentLeft></ImageTextComponentLeft>
      <SocialComponent></SocialComponent>
    </>
 );
}

export default HomeView;